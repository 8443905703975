import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import Calculator from '../../components/Calculator';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import {
  CommonPageSection,
  Content,
  ContentItemWrap,
  ContentItem,
  Sidebar,
  LinksBlock,
} from '../../components/CommonPageSection';
import { CommonPageText } from '../../components/SitePolicies';
import CTA from '../../components/CTA';

import './index.scss';


const PlagiarismFreeGuaranteePage = () => (
  <Layout
    pageWrapperClassName="plagiarism-free-guarantee-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Plagiarism-Free Guarantee | TakeAwayEssay.com"
      meta={[
        { name: 'description', content: '' },

        { name: 'robots', content: 'noindex,nofollow' },

        { property: 'og:title', content: 'Plagiarism-Free Guarantee | TakeAwayEssay.com' },
        { property: 'og:description', content: '' },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/plagiarism-free-guarantee.html' },
      ]}
    />

    <CommonPageSection>
      <Content>
        <ContentItemWrap>
          <VerticalTextBlock
            sectionClassName="common-page"
            dotsStyle="style-4"
            text="Plagiarism-Free"
          />

          <ContentItem>

            <h1>
              Plagiarism-Free Guarantee
            </h1>
            <CommonPageText pageType="no_plagiarism_guarantee" />

          </ContentItem>
        </ContentItemWrap>
      </Content>
      <Sidebar>
        <LinksBlock />
      </Sidebar>
    </CommonPageSection>

    <Calculator />

    <CTA isOnlyWaves />

  </Layout>
);

export default PlagiarismFreeGuaranteePage;
